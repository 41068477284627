/* Old Colour palette 

$primary-red: #AD2B2B;
$primary-green: #5ED8BC;
$primary-white: white;
$primary-black: black;
$primary-red-bright: #BA2026;
$primary-red-dark: #851537;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #78A1BB; 

*/

$primary-red: #ea2952;
$primary-blue: #112034;
$primary-green: #00D4FF;
$primary-white: white;
$primary-black: #14223D;
$primary-red-bright: #FF2E92;
$primary-red-dark: #ea2952;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #7b39ED; 

$window-background-colour: #f0f0f0;
$content-background-colour: white;

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 50vmin;
  pointer-events: none;
  z-index: 100;
}

.App-header {
  position: absolute;
  background-color: $primary-white;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $primary-black;
  top: 0px;
  width: 100%;
  height: 50px;
}

.App-header-logo {
  position: absolute;
  left: 10px;
  top: 8px;
  width: 32px;
  height: 32px;
  z-index: 100;
}

.Popup-header {
  width: 100%;
  height: 40px;
  font-size: 18px;
  background-color: $primary-red;
  color: $primary-white;
  text-align: center;
  line-height: 40px;
}

.App-header-back {
  position: absolute;
  left: 12px;
  top: 10px;
  width: 17px;
  height: 26px;
  background-image: url('../public/icons/back-arrow.png');
  background-size: cover;
  z-index: 1;
}

.App-header-avatar {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 32px;
  height: 32px;
}

.App-footer {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Height of the footer*/ 
    height: 60px;
    background: $primary-white;
    overflow: hidden;
    border-top: 1px solid $secondary-light-grey;
}

.App-sidebar {
  position: absolute;
  width: 190px;
  left: 0px;
  top: 50px;
  bottom: 0px;
  background: $primary-white;
  overflow: auto;
  border-left: 1px solid $secondary-light-grey;
}

.App-link {
  color: $secondary-blue;
}

.App-content {
  position: absolute;
  overflow: auto;
  top: 50px; 
  background-color: $primary-white;
  color: $primary-black;

  width: 990px;
  right: 0px;
  bottom: 0px;
  border-left: 1px solid $secondary-light-grey;
  border-right: 1px solid $secondary-light-grey;
}

.App-content-column {
  max-width:930px;
  margin: auto;
}

.App-content-column-narrow {
  max-width:430px;
  margin: auto;
}


.App-pageframeOuter {
  position: fixed;
  overflow: auto;
 /* background-color: red; */
  width: 100%;
  height: 100%;
  background-color: primary-white;
}

.App-pageframeInner {
  /* this needs to be position: relative so we can use absolute
     positioning of child elements */
  position: relative; 
  height: 100%;

  overflow: auto;
  margin: auto;
  background-color: $primary-white;

  width: 1184px;
}

.App-root {
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.tabBarContainer {
  position: relative;
  top: 0px;
  left: 0px;
  height: 160px;
}

.tabBarItem {
  position: relative;
  height: 40px;
  text-align: center;
}

.tabBarItem:hover {
  background-color: $primary-red-bright;
}

.tabBarIconContainer {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 5px;
}

.tabBarLabelContainer {
  position: absolute;
  left: 45px;
  top: 0px;
  height: 40px;
  text-align: center;
}

.tabBarIcon {
  position: relative;
  top: 8px;
  width: 28px;
  height: 28px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.tabBarLabel {
  position: relative;
  text-align: center;
  font-size: 13pt;
  top: 10px;
  color: black;
}

.tabBarLabelDisabled {
  position: relative;
  text-align: center;
  font-size: 13pt;
  color: $secondary-dark-grey;
  top: 10px;
}

.Popup-header {
  width: 100%;
  height: 40px;
  font-size: 18px;
  background-color: $primary-red;
  color: $primary-white;
  text-align: center;
  line-height: 40px;
}







/* Colour palette */


/* Old palette 
$primary-red: #AD2B2B;
$primary-green: #5ED8BC;
$primary-white: white;
$primary-black: black;
$primary-red-bright: #BA2026;
$primary-red-dark: #851537;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #78A1BB; 
 */

$primary-red-old: #AD2B2B;
$primary-blue: #112034;
$primary-red: #ea2952;
$primary-green: #00D4FF;
$primary-white: white;
$primary-black: #14223D;
$primary-red-bright: #FF2E92;
$primary-red-dark: #ea2952;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #7b39ED;  

$secondary-dark-blue: #081720;  

$window-background-colour: #f0f0f0;
$content-background-colour: white;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  color: $primary-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $window-background-colour;
  overflow: hidden;
}

input {
  width: 100%;
  font-size: 100%; 
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;

  border-radius: 3px;
  margin-bottom: 10px;
}


.react-toggle--checked .react-toggle-track {
  background-color: $primary-red !important;
  border-color: $primary-red !important;
}

.react-toggle-thumb {
  border-color: $primary-red !important;
}

@mixin hover-effect {
  filter: brightness(86%);
}

@mixin card-hover {
  @include hover-effect;
}

* {
  box-sizing: border-box;
}

a {
  color: $primary-red;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.avatar {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: $primary-white;
  font-size: 15px;
  text-align: center;;
  background-color: $secondary-blue;
  line-height: 32px;
  margin-right: 5px;
}

.headerTeamName {
  font-size: 20px;
  margin-top: 5px;
}

.avatarSmall {
  float: left;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: $primary-white;
  font-size: 8px;
  text-align: center;;
  background-color: $secondary-blue;
  line-height: 28px;
  margin-right: 3px;
}

.avatarImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buttonContainer {
  margin-left: 10px;
  margin-right: 10px;
}


.header {
  position: relative;
  background-image: linear-gradient(to bottom right,$primary-red-dark,$primary-red-dark);

  height : 50px;
  display: flex;
  align-items: center;
  justify-content:center;
  text-transform: capitalize; 
  overflow: hidden;
}

.headerIcon {
  position: relative;
  height: 50px;
  z-index: 0;
}

.headerIconContainer {
  position: absolute;
  margin: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  text-align: center;
}

.headerTitle {
  // background-image: linear-gradient(to bottom right,$primary-red-dark, $primary-red-dark);
  //background-image: linear-gradient(to bottom right,$primary-red-old, $primary-red-old);

  background: $primary-white;
  color: black;
  
  height : 50px;
  text-align: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid $secondary-light-grey;
}

.navTitle {
  color: $primary-black;
  text-align: center;
  margin-left: 0px;
  width: 100%;
  font-size: 20px;
  position: relative;
  top: 13px;
}


.btn {
  /* default for <button>, but useful for <a> */

  color: $primary-white;
  /*  background-color: $secondary-grey; */
  background-color: $secondary-grey;

  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  width: 100%;

  height: 44px;
  border: solid 1px transparent;
  border-radius: 18px;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;

  /* size comes from text & padding (no width/height) */
  padding: 0.5em 1em;

  outline: none;
}

.btn-small {
  color: $primary-white;
  /*  background-color: $secondary-grey; */
  background-color: $secondary-grey;

  /* default for <button>, but useful for <a> */
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  border: solid 1px transparent;
  border-radius: 10px;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;

  /* size comes from text & padding (no width/height) */
  padding: 0.5em 1em;
  outline: none;
}

/* Firefox: removes the inner border shown on focus */
.btn::-moz-focus-inner {
  border: none;
}

/* old-school "down" effect on clic + color tweak */
.btn:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.btn-primary {
  /* make sure colors have enough contrast! */
  color: $primary-white;
/*  background-color: $secondary-grey; */
  background-color: $secondary-blue;

}

/* inverse colors on mouse-over and focus */
.btn:hover,
.btn:focus,
.btn-small:hover,
.btn-small:focus,
.btn-primary:hover,
.btn-primary:focus {
  @include hover-effect;
}

.btn-google {
  /* make sure colors have enough contrast! */
  color: #000000;
  background-color: $primary-white;
  outline: none;
  display: flex;
  justify-content: center;
  border: 1px solid black;
  border-radius: 18px;
  padding-top: 12px;
}

.loginTroubleLoggingIn {
  text-align: center;
}

.onboardingSeparator {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
}

.dataGridContainer {
  margin-bottom: 30px;
}

.rdg-row-even {
  background-color: #eeeeee;
  color: black;

}

.rdg-row-odd {
  background-color: $primary-white;
  color: $primary-black;
}

.rdg-header-row {
  background-color: $primary-red;
  color: $primary-black;
}



.reportSelector {
  width: auto;
}

.react-tabs {
  padding-top: 10px;
}

.cellCenterAlign {
  text-align: center;
}

.cellRightAlign {
  text-align: right;
}

.headerCell {
  color: white;
  text-align: center;
}

.headerCellLeftAlign {
  color: white;
  text-align: left;
}

.teamCell {
  position: relative;
  width: 100%;
  background: $primary-white;
  height: 50px;
  border-bottom: 1px solid #E8EAED;
}

.teamCell:hover {
  @include card-hover;
}

.teamCellName {
  position: absolute;
  top: 8px;
  left: 50px;
  font-size: 16px;
  font-weight: 600;
}

.teamCellAdmin {
  position: absolute;
  top: 26px;
  left: 50px;
  font-size: 12px;
  color: $secondary-grey;
}

.teamCellNumber {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: $primary-red;
  color: $primary-white;
  border-radius: 30px;
}

progress {
  background-color: $primary-white;
  border-radius: 5px;
}

progress[value]::-webkit-progress-bar {
  background-color: $secondary-light-grey;
}

progress::-webkit-progress-value {
 background-color: rgba(0, 212, 255, 0.5);
}

.activeUsersProgressBar {
  width: 100px;
  height: 100px;
}

.userProgressBarsTable {
  margin: 0 auto; /* or margin: 0 auto 0 auto */
  width: 440px;
  height: 200px;
 // border: 1px solid black;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
}

.userProgressBarsRow {
  height: 100px;
}

.userProgressBarCell {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
}

.CircularProgressbar {
  stroke: $primary-red !important;

//  stroke: $primary-green !important;
}

.CircularProgressbar-path {
  stroke: $primary-red !important;
}

.reportExportButtons {
  float: right;
  padding-top: 10px;
  padding-bottom: 10px
}

.goalsCategoriesChart {
  height: 270px;
  width: 270px;
  margin: auto;
  margin-bottom: 20px;
}

.activityByWeekChart, .feedbackByWeekChart {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
}

.loadingSpinnerContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinnerImage {
  position: relative;
  top: 100px;
  width: 80px;
  height: 80px;
}

.permissionDeniedMessage {
  width: 100%;
  position: relative;
  top: 20px;
  text-align: center;
}

.cellDropdown {
  margin-left: 10px;
  font-size: 12px;
  overflow: auto;
  z-index: 100; 
}

.circles-popup-content {
  margin: auto;
  background-color: transparent;
  width: 350px !important;
  height: 425px !important;
  padding: 0px !important;
  border: none !important;
}

.circlesPopupContainer {
  width: 100%;
  height: 320px;
  overflow-y: auto;
  background: $primary-white;
}

.circlesPopupRow {
  position: relative;
  width: 100%;
  background: $primary-white;
  height: 50px;
  border-bottom: 1px solid #E8EAED;
}

.circlesPopupRowName {
  position: absolute;
  left: 50px;
  top: 15px;
}

.circlesPopupRowCheckbox {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
}

.circlesPopupOKButton {
  position: relative;
  top: 10px;
}

.noCirclesDefinedMessage {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.team-create-popup-content {
  margin: auto;
  padding: 0px !important;
  background-color: transparent;
  width: 350px !important;
  height: 270px !important;
  border: none !important;
}

.teamCreatePopupNameLabel {
  position: absolute;
  top: 50px;
  left: 10px;
}

.teamCreatePopupNameInput {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 330px;
}


.teamCreatePopupContactLabel {
  position: absolute;
  top: 120px;
  left: 10px;
}

.teamCreatePopupContactInput {
  position: absolute;
  top: 150px;
  left: 10px;
  width: 330px;
}

.teamCreatePopupOKButton {
  position: absolute;
  bottom: 20px;
  width: 100%;
}


.circle-create-popup-content {
  margin: auto;
  padding: 0px !important;
  background-color: transparent;
  width: 350px !important;
  height: 280px !important;
  border: none !important;
}


.circleCreatePopupOKButton {
  position: absolute;
  top: 220px;
  width: 100%;
}

.circleCreatePopupNameLabel {
  position: absolute;
  top: 50px;
  left: 10px;
}

.circleCreatePopupNameInput {
  position: absolute;
  top: 80px;
  left: 10px;
  width: 330px;
}

.circleCreatePopupDescriptionLabel {
  position: absolute;
  top: 120px;
  left: 10px;
}

.circleCreatePopupDescriptionInput {
  position: absolute;
  font-size: 16px;
  height: 50px;
  top: 150px;
  left: 10px;
  width: 330px;
}

.rdg {
  border: none !important;
}

.circleTogglesContainer {
  position: relative;
  height: 10px;
  width: 100%;
  text-align: right;
}

.circleToggles {
  position: absolute;
  height: 50px;
  right: 0px;
}

.circleToggleSelected {
  background-color: $primary-red-dark;
  border-radius: 30px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: $primary-white;
  margin-right: 10px;
}

.circleToggleNotSelected {
  background-color: $secondary-grey;
  border-radius: 30px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: $primary-white;
  margin-right: 10px;
}

.circleSelectorPopup {
  float: right;
  margin-top: 5px;
}

.enabledUsersSelectorButton {
  float: right;
  margin-top: 5px;
}

.enabledTeamsSelectorContainer {
  position: relative;
  height: 40px;
}

.enabledTeamsSelectorButton {
  position: relative;
  float: right;
  margin-top: 5px;
  margin-bottom: 30px;
}

.sectionTitle {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  left: 0px;
  color: $secondary-dark-grey;
  font-size: 14px;
}

.settingsForm {
  width: 380px;
}

.settingsTeamName, .settingsObjectiveName {
  width: 100%;
  height: 40px;
}

.createNewTeamButton {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 200px;
  float: left;
}

.cardRow {
  position: relative;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d3d3d3;
  background: #fff;
}

.cardRowText {
  position: absolute;
  top: 10px;
  left: 45px;
  width: 300px;
  height: 25px;
}

.cardRowPlusIcon {
  position: absolute;
  text-align: center;
  line-height: 28px;
  padding-top: 2px;
  padding-left: 1px;
  top: 7px;
  left: 10px;
  width: 28px;
  height: 28px;
  background-color: #14223d;
  border-radius: 14px;
}

.cardRowDeleteIcon {
  right: 10px;
}

.cardRowDeleteIcon, .cardRowIcon {
  position: absolute;
  top: 5px;
  width: 28px;
  height: 28px;
  color: #474448;
}

.circleSelectorBox {
  width: 100%;
  height: 40px;
  border: 1px solid #d3d3d3;
  padding-top: 10px;
  padding-left: 10px;
}

.settingsRecommendHabitsCard {
  transition: .3s;
  margin-bottom: 5px;
  border-radius: 3px;
  border-collapse: collapse;
  padding: 0;
  background: #fff;
  width: 100%;
  border: 1px solid #eff3f8;
  box-sizing: border-box;
  box-shadow: 0 4px 6px #e0e3e9;
}

.material-icons.md-light {
  color: #fff;
}

.material-icons.md-28 {
  font-size: 25px;
}

.select-habit-popup-content {
  margin: auto;
  background: $primary-white;
  width: 360px !important;
 // height: 270px !important;
  padding: 0px !important;
  border: none !important;
}

.selectHabitPopupOKButton {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selectHabitPopupSelect {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.editNudgeEmailTemplate {
  width: 100%;
  height: 200px;
}

.editNudgeInfoTriangle {
  float: right;
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.message-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 150px !important;
  padding: 0px !important;
  border: none !important;
}


.messagePopupMessage {
  position: absolute;

  width: 300px;
  top: 55px;
  height: 30px;
  width: 100%;
  text-align: center;
  background-color: $primary-white;

  text-align: center;
  font-size: 16px;
  border-radius: 5px;
}

.messagePopupOKButton {
  position: absolute;

  left: 126px;
  bottom: 7px;
}

